import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import VendorScreen from "../screens/MainScreens/VendorScreen";
import VendorProducts from "../Vendor/VendorProducts";
import VendorOrders from "../Vendor/VendorOrders";
import VendorAccount from "../Vendor/VendorAccount";
import VendorSignUp from "../screens/Authentication/VendorSignUp";
import VendorRecord from "../Vendor/StepperForm/VendorRecord";
import VendorAddress from "../Vendor/StepperForm/VendorAddress";
import VendorVirtualMarket from "../Vendor/StepperForm/VendorVirtualMarket";
import VendorConfirmData from "../Vendor/StepperForm/VendorConfirmData";

import ForgetPassword from "./ForgetPassword";

import Login from "../screens/Authentication/SignIn/Index";
import OrderDetail from "./OrderDetail";

export default function AllRoute({ user }) {
  return (
    <Router>
      {!user ? (
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/SignUp" element={<VendorSignUp />} />

          <Route path="/ForgetPassword" element={<ForgetPassword />} />

          <Route path="/VendorRecord" element={<VendorRecord />} />

          <Route path="/VendorAddress" element={<VendorAddress />} />

          <Route
            path="/VendorVirtualMarket"
            element={<VendorVirtualMarket />}
          />
          <Route path="/VendorConfirmData" element={<VendorConfirmData />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" exact element={<VendorScreen />} />
          <Route path="/VendorProducts" element={<VendorProducts />} />
          <Route path="/VendorOrders" element={<VendorOrders />} />
          <Route path="/OrderDetail" element={<OrderDetail />} />
          <Route path="/VendorAccount" element={<VendorAccount />} />
        </Routes>
      )}
    </Router>
  );
}
