import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DatePickerComponent({
  value,
  setValue,
  setError,
  error,
}) {
  const onChange = (e) => {
    setValue(e);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Fecha de nacimiento"
        inputFormat="dd/MM/yyyy"
        value={value}
        onChange={(e) => onChange(e)}
        renderInput={(params) => (
          <TextField
            required
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              alignContent: "center",
              // marginTop: 10,
              marginLeft: 4,
            }}
            {...params}
            // helperText={params?.inputProps?.placeholder}
          />
        )}
      />
      <div style={{ fontSize: 13, color: "red", position: "fixed" }}>
        {error}
      </div>
    </LocalizationProvider>
  );
}
