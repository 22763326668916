import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import Colors from "../../../components/Colors";
import styled from "styled-components";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { useState } from "react";
import { useStateValue } from "../../../ContextApi/StateProvider";
import axios from "../../../axios";
import AuthContext from "../../../components/context";
import { useContext } from "react";
const loginSchema = Yup.object().shape({
  email: Yup.string().required("Correo Electrónico es requerido"),
  password: Yup.string()
    .required("Contraseña es requerido")
    .min(6, "Al menos 6 caracteres"),
});
export default function SignIn({}) {
  const navigation = useNavigate();
  const [loading, setLoading] = useState();
  const [loginError, setLoginError] = useState("");
  const [firebaseError, setFirebaseError] = React.useState("");

  console.log(loginError, "loginError");
  const [{ vendor }, dispatch] = useStateValue();
  console.log(vendor, "vendorvendor");
  const authContext = useContext(AuthContext);

  const login = async (values) => {
    try {
      const vendor = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      if (vendor) {
        navigation("/");
      }
    } catch (error) {
      setLoginError("");
      setFirebaseError(error.message, "eeror");
    }
  };
  const handleSubmit = async (values) => {
    console.log(values, "myValues");
    axios
      .post("/api/v1/vendor/login", {
        email: values.email.toLowerCase(),
      })
      .then(async (res) => {
        console.log(res.data.vendor, "myResponce");
        authContext.setVendor(res.data.vendor);
        localStorage.setItem(
          "vendor",
          JSON.stringify(res.data.vendor, null, 4)
        );

        login(values);
      })
      .catch((err) => {
        setFirebaseError("");
        setLoginError(err.response.data.message);
      });
  };
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "lightgray",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Container style={{ width: "100%" }}>
          <Box marginTop={8} sx={{ boxShadow: 3 }} style={Styles.Box}>
            <Typography component="h3" variant="h6" style={Styles.LogInText}>
              Iniciar sesión
            </Typography>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <Form>
                <Field
                  component={TextField}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Correo Electrónico"
                  name="email"
                  disabled={false}
                />
                <Field
                  component={TextField}
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  disabled={false}
                />
                <ForgetContainer>
                  <div style={{ color: Colors.lightBlack }}>
                    ¿Olvidaste tu contraseña?
                  </div>
                  <ResetButton onClick={() => navigation("/ForgetPassword")}>
                    Restablecer{" "}
                  </ResetButton>
                </ForgetContainer>
                <div style={{ color: "red" }}>{loginError}</div>
                {firebaseError && (
                  <div style={{ color: "red" }}>
                 
                    {"La contraseña o el correo electrónico son incorrectos"}
                  </div>
                )}{" "}
                <button
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#4C9F6F",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    padding: 6,
                    color: "white",
                    marginTop: 10,
                    marginBottom: 20,
                    textTransform: "none",
                  }}
                >
                  <div>Iniciar sesión</div>
                </button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      <u>Términos y condiciones</u>
                    </Link>
                  </Grid>
                </Grid>
                <CssBaseline />
                <SignUpContainer>
                  <div
                    style={
                      {
                        // display: "flex",
                        // flexDirection: "column",
                        // justifyContent: "center",
                      }
                    }
                  >
                    <div style={{ fontWeight: "600" }}>
                      ¿No tienes una cuenta?
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        onClick={() => navigation("/SignUp")}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#4C9F6F",
                          width: 150,
                          borderRadius: 10,
                          padding: 6,
                          color: "white",
                          marginTop: 10,
                          marginBottom: 20,
                          textTransform: "none",
                        }}
                      >
                        Regístrate{" "}
                      </button>
                    </div>
                  </div>
                </SignUpContainer>
              </Form>
            </Formik>
          </Box>
        </Container>
      )}
    </>
  );
}

const Styles = {
  SignInButton: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 7,
    width: "50%",
    fontSize: 12,
  },
  OrStyling: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 20,
    color: "white",
    padding: 5,
    textAlign: "center",
  },
  Divider: {
    width: "50%",
    justifyContent: "center",
    alignSelf: "center",
  },
  Box: {
    padding: 30,
    borderRadius: 20,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  LogInButton: {
    backgroundColor: Colors.darkBlue,
    padding: 15,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    color: "white",
    cursor: "pointer",
  },

  SignUpButton: {
    backgroundColor: "#469617",
    borderRadius: 7,
    width: 150,
    fontSize: 12,
  },
  LogInText: { width: "100%", fontSize: 25, fontWeight: "600" },
  NoAccountText: { color: Colors.lightBlack, paddingTop: 10 },
};
const ForgetContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10;
  padding-bottom: 15;
`;
const ResetButton = styled.div`
  color: ${Colors.lightGreen};
  font-weight: 700;
  cursor: pointer;
`;
const SignUpContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

// import * as React from "react";
// import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
// import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import { Divider } from "@mui/material";
// import { Field, Formik, Form } from "formik";
// import { TextField } from "formik-material-ui";
// import { useNavigate } from "react-router-dom";
// import styled from "styled-components";

// import * as Yup from "yup";
// import Colors from "../../../components/Colors";

// import DatePickerComponent from "../../../components/DatePickerComponent";
// import { auth } from "../../../firebase";
// import {
//   createUserWithEmailAndPassword,
//   onAuthStateChanged,
//   RecaptchaVerifier,
//   signInWithEmailAndPassword,
//   signInWithPhoneNumber,
// } from "firebase/auth";

// const loginSchema = Yup.object().shape({
//   name: Yup.string().required("Full Name is not required"),
//   phone: Yup.number().required("Phone Number"),
//   password: Yup.string()
//     .required()
//     .min(6, "At least 6 characters")
//     .label("Password"),
// });

// export default function SignIn() {
//   const navigation = useNavigate();

//   const login = async (values) => {
//     try {
//       const user = await signInWithEmailAndPassword(
//         auth,
//         values.email,
//         values.password
//       );
//       console.log(user);
//       navigation("/");
//     } catch (error) {
//       console.log(error.message, "mesaage");
//     }
//   };
//   const handleSubmit = async (values) => {
//     console.log(values, "myValues");
//     // login(values);
//   };
//   return (
//     <Container style={{ width: "100%" }}>
//       <Box marginTop={8} sx={{ boxShadow: 3 }} style={Styles.Box}>
//         <Typography component="h3" variant="h6" style={Styles.SignUpText}>
//           SIGN Up
//         </Typography>
//         <Formik
//           initialValues={{
//             name: "",
//             password: "",
//             email: "",
//           }}
//           validationSchema={loginSchema}
//           onSubmit={(values) => {
//             handleSubmit(values);
//           }}
//         >
//           {({ errors, touched }) => (
//             <Form>
//               <Field
//                 component={TextField}
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Enter Password"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//                 disabled={false}
//               />
//               <Field
//                 component={TextField}
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="email"
//                 label="Enter email"
//                 type="email"
//                 id="email"
//                 disabled={false}
//               />

//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//                 style={Styles.SignUpButton}
//               >
//                 Sign Up
//               </Button>
//               <Grid container>
//                 <Grid item xs>
//                   <Link href="#" variant="body2">
//                     Terms and conditions
//                   </Link>
//                 </Grid>
//               </Grid>
//             </Form>
//           )}
//         </Formik>
//         <Divider orientation="horizontal" flexItem style={Styles.Divider}>
//           <Typography style={Styles.OrStyling}>OR</Typography>
//         </Divider>
//         <Typography component="h1" variant="h5">
//           Already have an account?
//         </Typography>
//         <Button
//           onClick={() => navigation(-1)}
//           type="submit"
//           variant="contained"
//           sx={{ mt: 3, mb: 1 }}
//           style={Styles.SignInButton}
//         >
//           SIGN IN
//         </Button>
//       </Box>
//     </Container>
//   );
// }

// const Styles = {
//   SignInButton: {
//     backgroundColor: Colors.darkBlue,
//     borderRadius: 7,
//     width: "50%",
//     fontSize: 12,
//   },
//   OrStyling: {
//     backgroundColor: Colors.darkBlue,
//     borderRadius: 20,
//     color: Colors.white,
//     padding: 5,
//     textAlign: "center",
//   },
//   Divider: {
//     width: "50%",
//     justifyContent: "center",
//     alignSelf: "center",
//   },
//   Box: {
//     padding: 30,
//     borderRadius: 20,
//     backgroundColor: Colors.white,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   SignUpButton: {
//     backgroundColor: Colors.darkBlue,
//   },
//   SignUpText: {
//     fontSize: 15,
//     width: "100%",
//   },
// };
// const FormField = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
