import logo from "./logo.svg";
import "./App.css";
import SignIn from "./screens/Authentication/SignIn/SignIn";
import SignUp from "./screens/Authentication/SignUp/SignUp";
import { createTheme, ThemeProvider } from "@mui/material";
import Login from "./screens/Authentication/SignIn/Index";
import CreateAcoount from "./screens/Authentication/SignUp/Index";
import AllRoute from "./components/AllRoute";
import { StateProvider } from "./ContextApi/StateProvider";
import reducer, { initialState } from "./ContextApi/reducer";
import { useState } from "react";
import { useEffect } from "react";
import AuthContext from "./components/context";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
function App() {
  const [vendor, setVendor] = useState();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentVendor) => {
      setLoading(true);
      setUser(currentVendor);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  console.log(user, "myUser");
  function getUser() {
    const u = localStorage.getItem("vendor");
    if (u == null || u == "" || u == "undefined") {
      console.log("no user");
      return;
    }
    console.log(u == "undefined", "context");
    const us = JSON.parse(u);
    setVendor(us);
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider value={{ vendor, setVendor }}>
      <div style={{ backgroundColor: "#F6F9FC" }}>
        {loading ? <div>loading ...</div> : <AllRoute user={user} />}
      </div>
    </AuthContext.Provider>
  );
}

export default App;

// import React from "react";

// import { Input } from "@material-ui/core";
// import Autocomplete from "react-google-autocomplete";

// function App() {

//   return (
// <div className="App">
//   <header className="App-header">
//     <div style={{ width: "250px" }}>
//       <span style={{ color: "black" }}>Material UI</span>
//       <Input
//         fullWidth
//         color="secondary"
//         inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
//           <Autocomplete
//             apiKey="AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0"
//             {...props}
//             onPlaceSelected={(selected) => console.log(selected)}
//           />
//         )}
//       />
//     </div>
//   </header>
// </div>
//   );
// }

// export default App;
// import React from "react";
// import ReactDOM from "react-dom";
// import Modal from "react-modal";
// import { Input } from "@material-ui/core";
// import Autocomplete from "react-google-autocomplete";
// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };

// // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

// function App() {
//   let subtitle;
//   const [modalIsOpen, setIsOpen] = React.useState(false);

//   function openModal() {
//     setIsOpen(true);
//   }

//   function afterOpenModal() {
//     // references are now sync'd and can be accessed.
//     subtitle.style.color = "#f00";
//   }

//   function closeModal() {
//     setIsOpen(false);
//   }

//   return (
//     <div>
//       <button onClick={openModal}>Open Modal</button>
//       <Modal
//         isOpen={modalIsOpen}
//         onAfterOpen={afterOpenModal}
//         onRequestClose={closeModal}
//         style={customStyles}
//         contentLabel="Example Modal"
//       >
//         <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
//         <button onClick={closeModal}>close</button>
//         <div>I am a modal</div>
//         <div className="App">
//           <header className="App-header">
//             <div style={{ width: "250px" }}>
//               <span style={{ color: "black" }}>Material UI</span>
//               <Input
//                 fullWidth
//                 color="secondary"
//                 inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
//                   <Autocomplete
//                     apiKey="AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0"
//                     {...props}
//                     onPlaceSelected={(selected) => console.log(selected)}
//                   />
//                 )}
//               />
//             </div>
//           </header>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default App;
