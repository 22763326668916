import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";

import VendorNavbarComponent from "../../Vendor/VendorNavbarComponent";
import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";
import VendorDashBoard from "../../Vendor/VendorDashBoard";
import VendorMoneyStatus from "../../Vendor/VendorMoneyStatus";
import VendorDashBoardChart from "../../Vendor/VendorDashBoardChart";

import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AuthContext from "../../components/context";
const drawerWidth = 250;

function VendorScreen(props) {
  const authVendor = useContext(AuthContext);
  console.log(authVendor, "authVendor");
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeTab, setActiveTabs] = useState("Tablero de tu Tienda");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();
  const [loading, setLoading] = useState();

  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <VendorDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", background: "#F6F9FC", width: "100%" }}>
      <VendorNavbarComponent handleDrawerToggle={handleDrawerToggle} />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              minHeight: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid
            style={{ width: "100%", height: "86vh" }}
            item
            md={8}
            lg={10}
            sm={12}
            xs={12}
          >
            <Grid>
              <Grid md={12} xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    padding: "10px",
                    letterSpacing: "1px",
                    marginTop: 90,
                  }}
                >
                  Tablero de tu Tienda
                </h2>
              </Grid>

              <Grid md={12} style={{}}>
                <VendorMoneyStatus />
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <iframe

                  filter={{ "vendorId": authVendor._id }}
                  style={{
                    // background: "#F1F5F4",
                    border: "none",
                    borderRadius: "2px",
                    // boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
                    height: "300px",
                    width: "50%",
                    // width: "200%",
                    overflow: "hidden",
                  }}
                  src="https://charts.mongodb.com/charts-basimart-rdbtm/embed/charts?id=63667875-8720-44ea-859b-d18c7c488c51&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
              </div>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

VendorScreen.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default VendorScreen;
