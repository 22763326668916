import { Button, Grid, Input } from "@mui/material";
import React, { useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";

import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { Field, Formik, Form } from "formik";
import * as Yup from "yup";

import "../../screens/Authentication/VendorSignUp/field.css";
import { useJsApiLoader, GoogleMap, MarkerF } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import Location from "../../components/Location";

const addressScheme = Yup.object().shape({
  complement: Yup.string().required("Your phone number is required"),
});
function VendorAddress() {
  const location = useLocation();
  const navigation = useNavigate();
  console.log(location.state);
  const params = location.state.data;
  console.log(params, "myparams");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [value, setValue] = useState();
  const [form, setForm] = useState();
  // const handleSubmit = (values) => {

  // };

  console.log(form, "values");

  const [age, setAge] = React.useState("");
  console.log(age, "age");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  console.log(lat);
  console.log(lng);

  const handleGuy = (event) => {
    setAge(event.target.value);
  };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0",
    libraries: ["places"],
  });
  if (!isLoaded) {
    return <div>map is loading</div>;
  }

  const center = {
    lat: lat ? lat : 40.7127753,
    lng: lng ? lng : -74.0059728,
  };

  const handleTab = (e) => {
    console.log(e);

    params.address = e;
    navigation("/VendorVirtualMarket", {
      state: { ...params },
    });
  };
  return (
    <Grid
      container
      md={12}
      xs={12}
      style={{ backgroundColor: "#F7F7F7", }}
    >
      <Grid
        item
        style={{
          backgroundColor: "#4C9F6F",
          height: 60,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
        }}
        xs={12}
        md={12}
      >
        <img
          style={{ width: 220, height: 100, margin: -20 }}
          src={require("../../assets/logo1.png")}
        />
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 100,
        }}
        container
        xs={12}
        md={12}
        lg={12}
      >
        <Grid md={7} xs={10} item>
          <div style={{}}>
            <div>
              <div>
                <div style={{ padding: 5 }}>
                  <div
                    style={{
                      fontSize: 40,
                      fontWeight: "700",
                    }}
                  >
                    Registro
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "700",
                    }}
                  >
                 ¡Muy bien! Ahora, registra tu dirección actual y elige la opción que aparece en la lista de selección. Una vez seleccionado, asegúrate de que la flecha en el mapa esté en el punto correcto en su dirección.
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      fontSize: 18,
                      fontWeight: "700",
                      color: "#4C9F6F",
                    }}
                  >
                    Paso 3:
                  </div>
                  <div style={{ marginTop: 20, }}>
                    <Location
                      handleTab={(e) => {
                        handleTab(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VendorAddress;
