export const paymentConfirming = [
  {
    id: 2,
    name: "Procesando Pedido",
  },
];
export const PaymentReceived = [
  {
    id: 2,
    name: "Pedido Entregado",
  },
];
