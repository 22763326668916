import { Button, Grid, Input } from "@mui/material";
import React, { useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";

import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useJsApiLoader, GoogleMap, MarkerF } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";

const addressScheme = Yup.object().shape({
  // virtualMarket: Yup.number().required("Your phone number is required"),
});
const VendorVirtualMarket = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [stoName, setStoName] = useState("");
  console.log(stoName, "stoName");
  const location = useLocation();
  const navigation = useNavigate();
  //   const params = location.state.data;
  const [form, setForm] = useState();
  const [disabled, setDisabled] = useState(true);
  const handleSubmit = async (values) => {
    setDisabled(true);
    setStoName(values.storeName);
    console.log(values);
    const val = await axios.post("/api/v1/vendor/checkStoreName", {
      storeName: values.storeName,
    });
    console.log(val.data.success, "mynewval");
    if (val.data.success) {
      console.log(stoName);
      location.state.storeName = values.storeName;
      setDisabled(false);
    }
    console.log(val);
    if (val.data.success) {
      setErrorMessage(val.data);
    } else {
      setErrorMessage(val.data);
      setForm({ address: { ...values } });
    }
  };
  console.log(location);

  return (
    <Grid
      container
      md={12}
      xs={12}
      style={{ backgroundColor: "#F7F7F7", minHeight: "100vh" }}
    >
      <Grid
        item
        style={{
          backgroundColor: "#4C9F6F",
          height: 60,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          width: "100%",
        }}
        xs={12}
        md={12}
      >
        <img
          style={{ width: 220, height: 100, margin: -20 }}
          src={require("../../assets/logo1.png")}
        />
      </Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 100,
        }}
        container
        xs={12}
        md={12}
        lg={12}
      >
        <Grid md={7} xs={10} item>
          <div style={{}}>
            <Formik
              initialValues={{
                storeName: "",
              }}
              validationSchema={addressScheme}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                values,

                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <div>
                  <Form encType="multipart/form-data">
                    <div>
                      <div style={{ padding: 5 }}>
                        <div
                          style={{
                            fontSize: 40,
                            fontWeight: "700",
                            marginBottom: 10,
                          }}
                        >
                          Tu super virtual
                        </div>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: "700",
                            marginBottom: 10,
                          }}
                        >
                         Ha llegado el momento de elegir el nombre de tu tienda virtual. Al hacer esto, recibirás un enlace para que puedas comenzar a publicitar y vender.
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            fontSize: 18,
                            fontWeight: "700",
                            color: "#4C9F6F",
                            marginBottom: 10,
                          }}
                        >
                          Step 4:
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Field
                            component={TextField}
                            type="storeName"
                            id="storeName"
                            name="storeName"
                            disabled={false}
                            // defaultValue="email"
                            values={values.storeName}
                            label="Nombre de la tienda"
                            onChange={handleChange}
                            style={{
                              color: "green",
                              borderColor: "black",
                              width: "80%",
                            }}

                            // variant="filled"
                          />

                          <button
                            style={{
                              backgroundColor: "#5CBE8F",
                              display: "flex",
                              justifyContent: "center",
                              color: "white",
                              cursor: "pointer",
                              width: "18%",
                              marginLeft: 5,
                              paddingTop: 15,
                              paddingBottom: 15,
                              borderRadius: 5,
                            }}
                          >
                            Verificar
                          </button>
                        </div>

                        {errorMessage && (
                          <label
                            style={
                              errorMessage === 500
                                ? { color: "red" }
                                : { color: "black" }
                            }
                          >
                            {errorMessage.message}
                          </label>
                        )}
                        {disabled && !errorMessage && (
                          <div>Verifica si el nombre de la tienda está tomado o no</div>
                        )}
                        {!disabled && (
                          <div
                            style={{
                              marginTop: 50,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              See what your virtual marketplace name will look
                              like:{"  "}
                            </div>
                            <div
                              style={{ fontSize: 16, color: "blue" }}
                            >{` https://test.basimart.com/MinistoreFront/${stoName}`}</div>
                          </div>
                        )}

                        <button
                          disabled={disabled}
                          onClick={() => {
                            navigation("/VendorConfirmData", {
                              state: {
                                ...location,
                              },
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#4C9F6F",
                            width: 120,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                            padding: 10,
                            color: "white",
                            marginTop: 20,
                          }}
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VendorVirtualMarket;
