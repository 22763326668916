import React, { useContext, useEffect, useState } from "react";
import Colors from "../components/Colors";
import { Grid } from "@mui/material";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import VendorOrderStatus from "./VendorOrderStatus";
import axios from "../axios";
import AuthContext from "../components/context";
const VendorMoneyStatus = ({}) => {
  const [orders, setOrders] = useState();
  const vendorAuth = useContext(AuthContext);
  const [vendor, setVendor] = useState()

  console.log(vendorAuth);
  const getOrderAmount = async () => {
    axios
      .get(`/api/v1/order/vendorOrderAmount/${vendorAuth?.vendor?._id}`)
      .then((res) => {
        console.log(res.data);
        axios.post(`/api/v1/vendor/checkStoreName`, { storeName: vendorAuth?.vendor?.storeName }).then((res) => {
          console.log(res.data);
          setVendor(res.data.store);
        });
        setOrders(res.data);
      });
  };
  useEffect(() => {
    getOrderAmount();
  }, [vendorAuth]);

  return (
    <Grid
      container
      xs={12}
      md={12}
      lg={12}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      {orders &&vendor&& (
        <Grid
          xs={10}
          md={3}
          item
          style={{
            backgroundColor: "#4C9F6F",

            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "600",
            }}
          >
            Comisión
          </div>
          <div style={{ color: "#4B566B", fontSize: 25, fontWeight: "700" }}>
            ₡
            {vendor?.commission
              ? orders?.commission[0]?.sum_val *
              (vendor?.commission / 100)                ? (
                orders?.commission[0]?.sum_val *
                (vendor?.commission / 100)
              ).toFixed(1)
                : 0
              : 0}
          </div>
          {/* <div style={{ color: "white", fontSize: 17, fontWeight: "600" }}>
          after associated vendor fees
        </div> */}
        </Grid>
      )}
      {orders && (
        <Grid
          xs={10}
          md={3}
          item
          style={{
            backgroundColor: "#4C9F6F",

            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "600",
            }}
          >
            Ventas de hoy{" "}
          </div>
          <div style={{ color: "#4B566B", fontSize: 25, fontWeight: "700" }}>
            ₡{orders?.today[0]?.sum_val?.toFixed(1)}
          </div>
          {/* <div style={{ color: "white", fontSize: 17, fontWeight: "600" }}>
          {orders?.today[0]?.sum_val}
        </div> */}
        </Grid>
      )}
      {orders && (
        <Grid
          xs={10}
          md={3}
          item
          style={{
            backgroundColor: "#4C9F6F",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 20,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: "600",
            }}
          >
            Ventas del Mes
          </div>
          <div style={{ color: "#4B566B", fontSize: 25, fontWeight: "700" }}>
            ₡{orders?.thisMonth[0]?.sum_val?.toFixed(1)}
          </div>
          {/* <div style={{ color: "white", fontSize: 17, fontWeight: "600" }}>
          {pendingOrderDate}
        </div> */}
        </Grid>
      )}
      <Grid
        container
        sx={12}
        md={12}
        item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
          md={2.9}
        >
          {orders?.allOrders && (
            <VendorOrderStatus
              icon={<WalletIcon />}
              text="Total de Pedidos"
              balance={orders?.allOrders?.length}
              mainContainer={orderStyles.orderMainContainer}
              childContainer={orderStyles.orderChildContainer}
              iconStyle={orderStyles.iconStyleTotal}
              textStyle={orderStyles.orderTextStyle}
              balanceStyle={orderStyles.orderQuantity}
            />
          )}
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
          md={2.9}
        >
          {orders?.pendingOrders && (
            <VendorOrderStatus
              icon={<WalletIcon />}
              text="Pedidos Confirmando"
              balance={orders?.pendingOrders.length}
              mainContainer={orderStyles.orderMainContainer}
              childContainer={orderStyles.orderChildContainer}
              iconStyle={orderStyles.iconStylePending}
              textStyle={orderStyles.orderTextStyle}
              balanceStyle={orderStyles.orderQuantity}
            />
          )}
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
          md={2.9}
        >
          {orders?.processingOrders && (
            <VendorOrderStatus
              icon={<ShoppingCartIcon />}
              text="Pedidos Confirmados"
              balance={orders.processingOrders.length}
              mainContainer={orderStyles.orderMainContainer}
              childContainer={orderStyles.orderChildContainer}
              iconStyle={orderStyles.iconStyleProcessing}
              textStyle={orderStyles.orderTextStyle}
              balanceStyle={orderStyles.orderQuantity}
            />
          )}
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
          md={2.9}
        >
          {orders?.deliveredOrders && (
            <VendorOrderStatus
              icon={<AllInboxIcon />}
              text="Pedidos Entregados"
              balance={orders.deliveredOrders.length}
              mainContainer={orderStyles.orderMainContainer}
              childContainer={orderStyles.orderChildContainer}
              iconStyle={orderStyles.iconStyleDelivered}
              textStyle={orderStyles.orderTextStyle}
              balanceStyle={orderStyles.orderQuantity}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VendorMoneyStatus;
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
    marginLeft: 7,
    marginRight: 7,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
    marginLeft: 7,
    marginRight: 7,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
    marginLeft: 7,
    marginRight: 7,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
    marginLeft: 7,
    marginRight: 7,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
