export const initialState = {
  cart: [],
  vendor: {},
  qty: 1,
};
export const getCartTotal = (cart) =>
  cart
    ?.map((item) => Number(item.price.replace("$", "")))
    ?.reduce((pre, curr) => pre + curr, 0);
const reducer = (state, action) => {
  console.log(action, "myactionperform");
  switch (action.type) {
    case "ADD_TO_CART": {
      const item = action.item;
      const updateQty = state.qty;
      console.log(updateQty, "updateQty 1");
      const existItem = state.cart.find((x) => x.id === item.id);
      if (existItem) {
        return { ...state };
      } else {
        return {
          ...state,
          cart: [...state.cart, action.item],
          qty: updateQty,
        };
      }
    }
    case "REMOVE_FROM_CART": {
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.id),
      };
    }
    case "INCREMENT_QTY": {
      const item = action.item;

      const existItem = state.cart.id === item.id;
      if (true) {
        const updateQty = state.qty + 1;
        return {
          ...state,
          cart: [...state.cart],
          qty: updateQty,
        };
      } else {
        return { ...state };
      }
    }
    case "DECREMENT_QTY": {
      const itemless = action.item;
      itemless["itemQty"] = 1;
      itemless.itemQty = itemless.itemQty - 1;
      const updateQty2 = state.qty - 1;
      console.log(updateQty2, "updateQty 2");

      const index2 = state.cart.findIndex((cart) => cart.id === action.id);
      state.cart[index2] = itemless;
      return {
        cart: [...state.cart],
        qty: updateQty2,
      };
    }
    case "VENDOR_ADDED": {
      const item = action.item;
      return {
        ...state,
        vendor: { ...item },
      };
    }
    default:
      return state;
  }
};
export default reducer;

// case "increament":
//   return {
//     ...state,
//     cart: [(state.cart.price += state.cart.price)],
//   };
// case "decrement":
//   return { count: state.count - 1 };
// default:
//   return state;
// }
