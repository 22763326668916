import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import Colors from "./Colors";
import styled from "styled-components";
import { useStateValue } from "../ContextApi/StateProvider";
import NavbarComponent from "./NavbarComponent";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const loginSchema = Yup.object().shape({
  email: Yup.string().required().label("email"),
});

export default function ForgetPassword() {
  const navigation = useNavigate();
  const [{ user }, dispatch] = useStateValue();
  const addUserToStore = (loginUser) => {
    dispatch({
      type: "USER_ADDED",
      item: loginUser,
    });
  };
  const resetPassword = async (values) => {
    try {
      const newPassword = await sendPasswordResetEmail(auth, values.email);
      console.log(newPassword, "newPasswordnewPassword");
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = async (values) => {
    console.log(values, "myValues");
    resetPassword(values);
    // const val = await axios.post("/api/v1/user/login", {
    //   email: values.email.toLowerCase(),
    // });
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#4C9F6F",
          height: 60,
          position: "fixed",
          top: 0,
        }}
      >
        <div>
          <img
            style={{ width: 220, height: 100, margin: -20 }}
            src={require("../assets/logo1.png")}
          />
        </div>
      </div>
      <Container
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box marginTop={8} sx={{ boxShadow: 3 }} style={Styles.Box}>
          <Typography component="h3" variant="h6" style={Styles.LogInText}>
            Log in
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
              handleSubmit(values);
              //   setEmail(values.email);
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <div>
                  <Field
                    component={TextField}
                    type="email"
                    id="email"
                    name="email"
                    // defaultValue="email"
                    values={values.email}
                    onChange={handleChange}
                    style={{
                      color: "green",
                      borderColor: "black",
                      width: "100%",
                      marginBottom: 10,
                    }}
                    label="E-mail"
                    // variant="filled"
                  />

                 <button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={Styles.SignInButton}
                  >
          Reset Password
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  );
}

const Styles = {
 
  OrStyling: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 20,
    color: "white",
    padding: 5,
    textAlign: "center",
  },
  Divider: {
    width: "50%",
    justifyContent: "center",
    alignSelf: "center",
  },
  Box: {
    padding: 30,
    borderRadius: 20,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
  },
  LogInButton: {
    backgroundColor: Colors.darkBlue,
    padding: 15,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    color: "white",
    cursor: "pointer",
  },

  SignInButton: {
    backgroundColor: "#4C9F6F",
    borderRadius: 7,
    width: "100%",
    fontSize: 12,
    paddingTop:10,
    paddingBottom:10,
    color:"white"
  },
  SignUpButton: {
    backgroundColor: "#4C9F6F",
    borderRadius: 7,
    width: "50%",
    fontSize: 12,
  },
  LogInText: { width: "100%", fontSize: 25, fontWeight: "600" },
  NoAccountText: { color: Colors.lightBlack, paddingTop: 10 },
};
const ForgetContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10;
  padding-bottom: 15;
`;
const ResetButton = styled.div`
  color: ${Colors.lightGreen};
  font-weight: 700;
`;
const SignUpContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
