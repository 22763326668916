import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Grid } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import VendorNavbarComponent from "./VendorNavbarComponent";
import VendorDashBoard from "./VendorDashBoard";
import VendorProductsTable from "./VendorProductsTable";
import { useStateValue } from "../ContextApi/StateProvider";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import axios from "../axios";
import AuthContext from "../components/context";
import Colors from "../components/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
const drawerWidth = 250;

function VendorProducts(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();

  const [activeTab, setActiveTabs] = useState("Productos");
  const [loading, setLoading] = useState();
  const [addProductId, setAddProductId] = useState([]);
  console.log(addProductId, "addProductId");
  // const [{ vendor }, dispatch] = useStateValue()
  const [products, setProducts] = useState();
  console.log(products, "ddfdfdd");

  const auth = useContext(AuthContext);
  const vendorProduct = auth?.vendor;
  console.log(auth, "myVendor");
  useEffect(() => {
    async function getProducts() {
      axios.get(`/api/v1/vendor/${auth?.vendor?._id}`).then((res) => {
        console.log(res.data.vendorId.products, "getProductVendor");

        setProducts(res?.data?.vendorId?.products);
      });
    }
    getProducts();
  }, [auth]);
  // useEffect(() => {
  //   async function getProducts() {
  //     axios
  //       .post(`/api/v1/allVendorProduct`, {
  //         id: addProductId,
  //       })
  //       .then((res) => {
  //         console.log(res.data, "myrespon");

  //         setProducts(res.data.products);
  //       });
  //   }
  //   getProducts();
  // }, [addProductId]);

  const drawer = (
    <Grid
      md={3}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <VendorDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        background: "#F6F9FC",
        width: "100%",
        height: "100vh",
      }}
    >
      <VendorNavbarComponent handleDrawerToggle={handleDrawerToggle} />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid item md={12} lg={10} sm={12} xs={12}>
            <Grid>
              <Grid md={12} xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    marginTop: 100,
                    marginBottom: -20,
                  }}
                >
                  Productos
                </h2>
              </Grid>
              <Grid
                style={{ display: "flex", justifyContent: "flex-end" }}
                md={12}
                xs={12}
              ></Grid>
              <Grid item md={12} lg={10} sm={12} xs={12}>
                {products?.length > 0 && products ? (
                  <VendorProductsTable data2={products} />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "Center",
                      fontSize: 25,
                      fontWeight: "600",
                    }}
                  >
                    Sin productos
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

VendorProducts.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default VendorProducts;

const balanceStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  todayOrders: {
    backgroundColor: Colors.seaGray,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  MonthlyOrders: {
    backgroundColor: Colors.seaBlue,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  totalOrders: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  iconStyle: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  textStyle: { color: "white", fontSize: 17, fontWeight: "600" },
  balanceStyle: { color: "#4B566B", fontSize: 25, fontWeight: "700" },
};
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
