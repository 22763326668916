import { Button, Grid, Input } from "@mui/material";
import React, { useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TextField } from "formik-material-ui";
// import TextField from "@mui/material/TextField";
import "react-phone-number-input/style.css";

import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { useLocation, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import axios from "../../axios";
import { useStateValue } from "../../ContextApi/StateProvider";
import AuthContext from "../../components/context";
import { useContext } from "react";

const addressScheme = Yup.object().shape({
  phone: Yup.number().required("Your phone number is required"),
  email: Yup.string().required("Your email is required"),
});
function VendorConfirmData() {
  const location = useLocation();
  const navigation = useNavigate();
  const params = location.state.state;
  const newparams = location.state;

  console.log(params, "paramsdata");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [value, setValue] = useState();
  const [form, setForm] = useState();
  const [loading, setLoading] = useState();
  const [user, setUser] = React.useState({});
  const authContext = useContext(AuthContext);

  const [{ vendor }, dispatch] = useStateValue();
  console.log(vendor);
  console.log(form, "values");
  const addVendorToStore = (user) => {
    dispatch({
      type: "VENDOR_ADDED",
      item: user,
    });
  };
  const [conformDisable, setConformDisable] = useState(false);
  const handleSubmit = async (values) => {
    console.log(values, "finalvalues");
    setConformDisable(true);
    setLoading(true);
    {
      conformDisable == false &&
        axios
          .post(
            "/api/v1/vendor/new",
            {
              cellPhone: params.phone,
              email: params.email,
              firstName: params.firstName,
              image: params.image,
              lastName: params.lastName,
              birthDate: params.birthDate,
              documentNumber: params.documentNo,
              guy: params.guy,
              address: JSON.stringify(params.address),
              storeName: params.storeName,
              adminPaymentType: JSON.stringify({
                onDeliveryChecked: true,
                snipeChecked: true,
                transferChecked: true,
              }),
              adminDeliveryType: JSON.stringify({
                priority: true,
                standard: true,
                faster: true,
                deliveryTimePriority: 1,
                deliveryPricePriority: 5,
                deliveryTimeStandard: 24,
                deliveryPriceStandard: 0,
                deliveryTimeFaster: 4,
                deliveryPriceFaster: 2,
              }),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res.data.vendor, "mayresponsedata");
            authContext.setVendor(res.data.vendor);
            localStorage.setItem(
              "vendor",
              JSON.stringify(res.data.vendor, null, 4)
            );
          });
      register(values);
    }
    setLoading(false);
  };
  const [age, setAge] = React.useState("");
  console.log(age, "age");

  const register = async (values) => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      console.log(user);
      addVendorToStore(user);
      navigation("/");
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "lightgray",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid
          container
          md={12}
          xs={12}
          style={{ backgroundColor: "#F7F7F7", minHeight: "100vh" }}
        >
          <Grid
            item
            style={{
              backgroundColor: "#4C9F6F",
              height: 60,
              display: "flex",
              alignItems: "center",
              position: "fixed",
              width: "100%",
            }}
            xs={12}
            md={12}
          >
            <img
              style={{ width: 220, height: 100, margin: -20 }}
              src={require("../../assets/logo1.png")}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 100,
            }}
            container
            xs={12}
            md={12}
            lg={12}
          >
            <Grid md={7} xs={10} item>
              <div style={{}}>
                <Formik
                  initialValues={{
                    ...params,
                  }}
                  validationSchema={addressScheme}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({
                    values,

                    handleChange,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <div>
                      <Form>
                        <div>
                          <div style={{ padding: 5 }}>
                            <div
                              style={{
                                fontSize: 30,
                                fontWeight: "700",
                              }}
                            >
                             Confirma tus datos
                            </div>
                            <div
                              style={{
                                fontWeight: "600",
                                color: "#4C9F6F",
                              }}
                            >
                              Correo Electrónico:
                            </div>
                            <div style={{ marginBottom: 10 }}>
                              {params.email}
                            </div>
                            <div
                              style={{
                                fontWeight: "600",
                                color: "#4C9F6F",
                              }}
                            >
                             Dirección completa:
                            </div>
                            <div style={{ marginBottom: 10 }}>
                              {params.address.loca}
                            </div>
                            <div
                              style={{
                                fontWeight: "600",
                                color: "#4C9F6F",
                              }}
                            >
                             Nombre completo:
                            </div>
                            <div style={{ marginBottom: 10 }}>
                              {params.firstName} {params.lastName}
                            </div>
                            <div
                              style={{
                                fontWeight: "600",
                                color: "#4C9F6F",
                              }}
                            >
                              Nombre de la tienda
                            </div>
                            <div style={{ marginBottom: 10 }}>
                              {params.storeName}
                            </div>

                            <div
                              style={{
                                fontWeight: "600",
                                color: "#4C9F6F",
                              }}
                            >
                              Número de Teléfono:
                            </div>
                            <div style={{ marginBottom: 10 }}>
                              {params.phone}
                            </div>
                            <div
                              style={{
                                fontWeight: "600",
                                color: "#4C9F6F",
                              }}
                            >
                              Documento de identificación:
                            </div>
                            <div style={{ marginBottom: 10 }}>
                              {params.documentNo}
                            </div>
                            <div
                              disabled={conformDisable}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <button
                                type="submit"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: conformDisable
                                    ? "lightgray"
                                    : "#4C9F6F",
                                  width: 120,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 10,
                                  padding: 10,
                                  color: "white",
                                  marginTop: 20,
                                }}
                              >
                                Continuar
                              </button>
                              <div
                                style={{
                                  cursor: "pointer",
                                  width: 130,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 10,
                                  padding: 10,
                                  color: "#4C9F6F",
                                  marginTop: 20,
                                  marginLeft: 5,
                                  borderColor: "#4C9F6F",
                                  borderWidth: 1,
                                }}
                                onClick={() =>
                                  navigation("/SignUp", {
                                    state: {
                                      defaultData: params,
                                    },
                                  })
                                }
                              >
                                Cambiar datos
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default VendorConfirmData;
