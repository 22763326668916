import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import VendorNavbarComponent from "./VendorNavbarComponent";
import VendorDashBoard from "./VendorDashBoard";
import AuthContext from "../components/context";
import { useContext } from "react";
import VendorOrderTable from "./VendorOrderTable";
import VendorProductsTable from "./VendorProductsTable";
import { useStateValue } from "../ContextApi/StateProvider";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import axios from "../axios";

import Colors from "../components/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import io from "socket.io-client";
const drawerWidth = 250;

const status = [
  {
    id: 1,
    type: "Confirmando Pedido",
  },
  {
    id: 2,
    type: "Pedido Confirmado",
  },
  {
    id: 3,
    type: "Procesando Pedido",
  },
  {
    id: 4,
    type: "Pedido Entregado",
  },
  {
    id: 5,
    type: "Cancelada",
  },
];

function VendorOrders(props) {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();

  // const [{ vendor }, dispatch] = useStateValue()
  const [products, setProducts] = useState();
  // console.log(vendor, "vendor")

  const [activeTab, setActiveTabs] = useState("Ordenar");
  const [order, setOrder] = useState([]);
  const [orderStatus, setOrderStatus] = useState();
  const [vendor, setVendor] = useState();

  const handleStatus = async (e) => {
    console.log(e, "aisle");
    setOrderStatus(e.target.value);
    console.log(auth.vendor._id);
    setLoading(true);
    const req = await axios.post(`/api/v1/vendor/order/${auth.vendor._id}`, {
      type: e.target.value,
    });
    console.log(req.data.order, "orders");
    setOrder(req?.data?.order);
    setLoading(false);
  };

  console.log(order, "orderorder");
  const [loading, setLoading] = useState();
  const auth = useContext(AuthContext);
  console.log(auth, "myVendor");
  console.log(order, "myfetchorder");

  useEffect(() => {
    async function fetchData() {
      console.log(auth.vendor._id);
      setLoading(true);
      const req = await axios.post(`/api/v1/vendor/order/${auth.vendor._id}`);
      console.log(req.data.order, "orders");
      setOrder(req?.data?.order);
      setLoading(false);
      axios.get(`/api/v1/vendor/${auth.vendor._id}`).then((res) => {
        console.log(res.data);
        setVendor(res.data.vendorId);
      });
    }

    const socket = io(
      "https://orca-app-2hy8c.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    ).connect();
    socket.on("newThought", (thought) => {
      console.log(thought, "hello");
      return fetchData();
    });
    fetchData();
  }, [auth]);
  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <VendorDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        background: "#F6F9FC",
        width: "100%",
        height: "100vh",
      }}
    >
      <VendorNavbarComponent handleDrawerToggle={handleDrawerToggle} />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid item md={12} lg={10} sm={12} xs={12}>
            <Grid>
              <Grid md={12} xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    marginTop: 100,
                    marginBottom: -20,
                  }}
                >
                  Ordenar
                </h2>
              </Grid>
              <Grid
                style={{ width: "20%", padding: 20 }}
                md={2}
                xs={4}
                lg={2}
                sm={2}
                xl={2}
              >
                <InputLabel id="aisle" style={{}}>
                  Estado del pedido
                </InputLabel>
                <Select
                  displayEmpty={false}
                  id="aisle"
                  value={orderStatus}
                  onChange={handleStatus}
                  label="Estado del pedido"
                  style={{ width: "100%" }}
                >
                  {status.map((item) => (
                    <MenuItem
                      // onClick={() => setCategory(item.values)}
                      value={item.type}
                    >
                      {item.type}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={12} lg={10} sm={12} xs={12}>
                {order && <VendorOrderTable order={order} vendor={vendor} />}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

VendorOrders.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default VendorOrders;

const balanceStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  todayOrders: {
    backgroundColor: Colors.seaGray,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  MonthlyOrders: {
    backgroundColor: Colors.seaBlue,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  totalOrders: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  iconStyle: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  textStyle: { color: "white", fontSize: 17, fontWeight: "600" },
  balanceStyle: { color: "#4B566B", fontSize: 25, fontWeight: "700" },
};
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
