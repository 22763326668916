import React, { useState } from "react";
import ListIcon from "@mui/icons-material/List";

import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ReplayIcon from "@mui/icons-material/Replay";
import BookIcon from "@mui/icons-material/Book";
import { useNavigate } from "react-router-dom";

const VendorDashBoard = (props) => {
  console.log(props);

  return (
    <div>
      <HeaderButton
        text="Tablero de tu Tienda"
        btnColor="black"
        textColor="white"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/shop.png")}
      />
      <HeaderButton
        text="Productos"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/deals.png")}
      />
      <HeaderButton
        text="Ordenar"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/retry.png")}
      />
      <HeaderButton
        text="Configuraciones de la cuenta"
        btnColor="white"
        textColor="black"
        activeTab={props.activeTab}
        setActiveTabs={props.setActiveTabs}
        img={require("../assets/recipies.png")}
      />
    </div>
  );
};

export default VendorDashBoard;

const HeaderButton = (props) => {
  const navigation = useNavigate();
  const handleChange = (props) => {
    props.setActiveTabs(props.text);
    if (props.text === "Tablero de tu Tienda") {
      navigation("/");
    } else if (props.text === "Ordenar") {
      navigation("/VendorOrders");
    } else if (props.text === "Productos") {
      navigation("/VendorProducts");
    } else {
      navigation("/VendorAccount");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: props.activeTab === props.text ? "#4C9F6F" : "white",
        marginLeft: 5,
        marginRight: 9,
        paddingLeft: 15,
        paddingTop: 9,
        paddingBottom: 9,
        borderRadius: 6,
        marginBottom: 2,
        width: "97%",
        cursor: "pointer",
      }}
      onClick={() => handleChange(props)}
    >
      <div style={{ marginRight: 2 }}>
        <img
          style={{
            width: 20,
            height: 20,
          }}
          src={props.img}
        />
      </div>
      <div
        style={{
          color: props.activeTab === props.text ? "white" : "black",
          fontSize: 14,
          fontWeight: "800",
        }}
      >
        {props.text}
      </div>
    </div>
  );
};
