import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, IconButton, Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { paymentConfirming, PaymentReceived } from "./dummy";
import MenuItem from "@mui/material/MenuItem";
import toast, { Toaster } from "react-hot-toast";
import axios from "../axios";
import { ArrowBackTwoTone } from "@material-ui/icons";
import moment from "moment/moment";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import io from "socket.io-client";
const OrderDetail = () => {
  const location = useLocation();
  const params = location.state;
  console.log(params.status, "dattatatat");
  const [page, setPage] = React.useState(0);

  const [category, setCategory] = useState();
  const [status, setStatus] = React.useState();
  const [order, setOrder] = useState();

  console.log(status, "status");
  const navigation = useNavigate();

  const [storeImage, setStoreImage] = useState(null);
  const [img, setImg] = useState(null);
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
        setStoreImage(e.target.result);
      };
    }
  };
  const handleUpdate = async () => {
    await axios
      .put(
        `/api/v1/orders/paymentProof/${order?._id}`,
        {
          pop: img,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((res) => console.log(res.data.paymentImg, "paymentImg"));
    notify("Image uploaded successfully");
  };
  useEffect(() => {
    const getStatus = () => {
      axios
        .get(`/api/v1/allOrdersDetail/${params._id}`)
        .then((res) => {
          console.log(res);
          setStatus(res.data.allOrdersDetail.status);
          setOrder(res.data.allOrdersDetail);
          setStoreImage(res.data.allOrdersDetail?.pop);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const socket = io(
      "https://orca-app-2hy8c.ondigitalocean.app/api/socket",

      {
        transports: ["websocket"],
      }
    );
    socket.on("newThought", (thought) => {
      console.log(thought, "hello");
      getStatus();
    });
    getStatus();
  }, [params]);
  const handleStatus = async (e) => {
    console.log(e, "orderDetails");
    setStatus(e.target.value);
  };
  const notify = (e) => toast(e);

  const handleChange = async () => {
    await axios
      .put(`/api/v1/allOrdersDetail/status/${params._id}`, {
        status: status,
      })
      .then((res) => {
        console.log(res.data, "response from order");
        notify("Status updated successfully");
      });
  };

  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };
  // const date = new Date(order.purchaseDate)
  //   .addHours(order?.deliveryTime?.deliveryTime)
  //   .toLocaleString();
  // console.log(date.split(), "mydate");
  return (
    <>
      <Toaster />
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 40,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <h1 style={{ fontWeight: "bold", fontSize: 20 }}>
            Detalles del pedido
          </h1>
        </Grid>
        <Grid>
          <ArrowBackTwoTone
            style={{
              height: 30,
              width: 30,
              color: "black",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            item
            onClick={() => navigation(-1)}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          lg={10}
          md={12}
          sm={12}
          sx={12}
          style={{
            width: "100%",
            padding: 20,
            backgroundColor: "#F6F9FC",
          }}
        >
          {order && (
            <Grid container>
              <Grid style={{ padding: "15px" }} lg={6} md={6} sm={12} sx={12}>
                <Grid container style={styles.card}>
                  <Grid lg={6} md={6} sm={6} sx={12}>
                    <h4 style={{ margin: 0, padding: "10px" }}>
                      Order summary
                    </h4>
                    <Grid container>
                      <Grid container>
                        <Grid lg={4} md={4} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Ship by:</p>
                        </Grid>
                        <Grid lg={8} md={8} sm={12} sx={12}>
                          <p style={styles.hightLightTxt}>
                            {moment(
                              new Date(order.purchaseDate).addHours(
                                order?.deliveryTime?.deliveryTime
                              )
                            ).format("DD-MM-YYYY, HH-mm-ss A")}
                          </p>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid lg={4} md={4} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Fecha de compra:</p>
                        </Grid>
                        <Grid lg={8} md={8} sm={12} sx={12}>
                          <p style={styles.valueTxt}>
                            {moment(order.purchaseDate).format(
                              "DD-MM-YYYY, HH-mm-ss A"
                            )}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid lg={6} md={6} sm={6} sx={12}>
                    <Grid container>
                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Método de Pago:</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.hightLightTxt}>
                            {order?.paymentType?.type}
                          </p>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Depósito:</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.valueTxt}>Basimart</p>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Canal de ventas:</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.valueTxt}>Basimart.com</p>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Estado del pedido :</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          {order && (
                            <p style={styles.valueTxt}>{order?.status}</p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    sm={12}
                    lg={12}
                    xl={12}
                    xs={12}
                    spacing={2}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      justifySelf: "space-between",
                      alignContent: "space-between",
                    }}
                  >
                    <Grid item md={6} sm={6} lg={6} xl={6} xs={12}>
                      <>
                        {order && (
                          <FormControl sx={{ width: "100%" }}>
                            {order.status !== "Pedido Entregado" &&
                              order.status !== "Cancelada" &&
                              order?.paymentType?.type ===
                                "Pagar en efectivo" &&
                              order.status === "Confirmando Pedido" && (
                                <>
                                  <>
                                    <InputLabel id="status">
                                      Estado de actualización
                                    </InputLabel>
                                    <Select
                                      displayEmpty={false}
                                      id="status"
                                      value={status}
                                      onChange={handleStatus}
                                      label="Estado de actualización
"
                                    >
                                      {paymentConfirming.map((item) => (
                                        <MenuItem
                                          onClick={() =>
                                            setCategory(item.values)
                                          }
                                          value={item.name}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </>
                                </>
                              )}
                          </FormControl>
                        )}
                      </>
                      <>
                        {order && (
                          <FormControl sx={{ width: "100%" }}>
                            {order.status !== "Pedido Entregado" &&
                              order.status !== "Cancelada" &&
                              order?.paymentType?.type ===
                                "Pagar en efectivo" &&
                              order.status === "Pedido Confirmado" && (
                                <>
                                  <>
                                    <InputLabel id="status">
                                      Estado de actualización
                                    </InputLabel>
                                    <Select
                                      displayEmpty={false}
                                      id="status"
                                      value={status}
                                      onChange={handleStatus}
                                      label="Estado de actualización
"
                                    >
                                      {PaymentReceived.map((item) => (
                                        <MenuItem
                                          onClick={() =>
                                            setCategory(item.values)
                                          }
                                          value={item.name}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </>
                                </>
                              )}
                          </FormControl>
                        )}
                      </>
                      <>
                        {order && (
                          <FormControl sx={{ width: "100%" }}>
                            {order.status !== "Pedido Entregado" &&
                              order.status !== "Cancelada" &&
                              (order?.paymentType?.type === "SINPE Móvil" ||
                                order?.paymentType?.type ===
                                  "Transferencia bancaria") &&
                              order.status === "Procesando Pedido" && (
                                <>
                                  <>
                                    <InputLabel id="status">
                                      Estado de actualización
                                    </InputLabel>
                                    <Select
                                      displayEmpty={false}
                                      id="status"
                                      value={status}
                                      onChange={handleStatus}
                                      label="Estado de actualización
"
                                    >
                                      {PaymentReceived.map((item) => (
                                        <MenuItem
                                          onClick={() =>
                                            setCategory(item.values)
                                          }
                                          value={item.name}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </>
                                </>
                              )}
                          </FormControl>
                        )}
                      </>
                    </Grid>
                    <Grid item md={6} sm={6} lg={6} xl={6} xs={12}>
                      <>
                        {order.status !== "Pedido Entregado" &&
                          order.status !== "Cancelada" &&
                          order?.paymentType?.type === "Pagar en efectivo" &&
                          order.status === "Confirmando Pedido" && (
                            <Grid
                              md={9}
                              xs={6}
                              onClick={() => handleChange()}
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: "white",
                                backgroundColor: "#4C9F6F",
                                padding: 10,
                                borderRadius: 10,
                                marginRight: 30,
                                cursor: "pointer",
                                display: "flex",
                                alignItem: "center",
                                justifyContent: "center",
                                marginBottom: -30,
                                marginTop: 5,
                              }}
                            >
                              Guardar Cambios
                            </Grid>
                          )}
                      </>
                      <>
                        {order.status !== "Pedido Entregado" &&
                          order.status !== "Cancelada" &&
                          (order?.paymentType?.type === "SINPE Móvil" ||
                            order?.paymentType?.type ===
                              "Transferencia bancaria") &&
                          order.status === "Procesando Pedido" && (
                            <Grid
                              md={2.5}
                              xs={6}
                              onClick={() => handleChange()}
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: "white",
                                backgroundColor: "#4C9F6F",
                                padding: 10,
                                borderRadius: 10,
                                marginRight: 30,
                                cursor: "pointer",
                                display: "flex",
                                alignItem: "center",
                                justifyContent: "center",
                                marginBottom: -30,
                                marginTop: 5,
                              }}
                            >
                              Save
                            </Grid>
                          )}
                      </>
                      {order.status !== "Pedido Entregado" &&
                        order.status !== "Cancelada" &&
                        order?.paymentType?.type === "Pagar en efectivo" &&
                        order.status === "Pedido Confirmado" && (
                          <Grid
                            md={2.5}
                            xs={6}
                            onClick={() => handleChange()}
                            style={{
                              fontSize: 15,
                              fontWeight: 700,
                              color: "white",
                              backgroundColor: "#4C9F6F",
                              padding: 10,
                              borderRadius: 10,
                              marginRight: 30,
                              cursor: "pointer",
                              display: "flex",
                              alignItem: "center",
                              justifyContent: "center",
                              marginBottom: -40,
                              marginTop: 5,
                            }}
                          >
                            Save
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                style={{ padding: "15px", width: "100%" }}
                lg={6}
                md={6}
                sm={12}
                sx={12}
              >
                <Grid container style={styles.card}>
                  <Grid lg={12} md={12} sm={12} sx={12}>
                    <Grid container>
                      <Grid lg={12} md={12} sm={12} sx={12}>
                        <h4 style={{ margin: 0 }}>Ship to</h4>
                        <p style={styles.buyerName}>{params.shipTo}</p>
                        {/* <p style={styles.titleTxt}>{params.shipTo}</p> */}
                      </Grid>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Teléfono del Usuario:</p>
                        <p style={styles.buyerName}>{params.cellPhone}</p>
                      </Grid>
                      {/* <Grid lg={12} md={12} sm={12} sx={12}>
                    </Grid> */}
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Nombre del Usuario:</p>
                        <p style={styles.buyerName}>{params.buyerName}</p>
                      </Grid>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Código Postal:</p>

                        <p style={styles.buyerName}>
                          {order.deliveryAddress.zipCode}
                        </p>
                      </Grid>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        sx={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <p style={styles.titleTxt}>Otros Datos:</p>

                        <p style={styles.buyerName}>
                          {order.deliveryAddress.addressOptional}
                        </p>
                      </Grid>
                      {order.leaveAtDoor && (
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          sx={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <p style={styles.titleTxt}>
                            Instrucciones al repartidor:
                          </p>
                          <p style={styles.buyerName}>
                            leave the parcel at the customer door
                          </p>
                        </Grid>
                      )}
                      {order.deliveryAddress.instruction && (
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          sx={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <p style={styles.titleTxt}>Más instrucciones</p>
                          <p style={styles.buyerName}>
                            {order.deliveryAddress.instruction}
                          </p>
                        </Grid>
                      )}

                      {/* <Grid lg={12} md={12} sm={12} sx={12}>
                    </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Order detail table */}
          {order && (
            <div>
              {order?.paymentType?.type === "Pagar en efectivo" && (
                <>
                  <>
                    {order?.pop ? (
                      <div>Detalles de verificación de pago</div>
                    ) : (
                      <div>Subir comprobante de pago</div>
                    )}
                  </>
                  <Grid
                    container
                    md={8}
                    sm={8}
                    xs={8}
                    xl={8}
                    lg={8}
                    justifyContent={"space-between"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      border: "1px solid #ccc",
                      borderRadius: "6px",
                      padding: 15,
                    }}
                  >
                    {order?.paymentType?.type === "Pagar en efectivo" && (
                      <Grid item md={4} sm={4} xs={12} xl={4} lg={4}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: 23,
                          }}
                        >
                          <img
                            src={storeImage}
                            style={{
                              display: "flex",
                              width: 100,
                              height: 100,
                              alignSelf: "center",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                              justifySelf: "center",
                              border: "1px solid #ccc",
                            }}
                          />

                          <input
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleChangeImage}
                            id="contained-button-file"
                          />
                          <label
                            style={{ alignSelf: "center" }}
                            htmlFor="contained-button-file"
                          >
                            <IconButton
                              style={{ backgroundColor: "lightgray" }}
                              component="span"
                            >
                              <AddPhotoAlternateIcon />
                            </IconButton>
                          </label>
                        </div>
                        <button
                          onClick={handleUpdate}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#2D634C",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                            padding: 10,
                            color: "white",
                            borderWidth: 0,
                            marginTop: 30,
                          }}
                        >
                          Enviar
                        </button>
                      </Grid>
                    )}
                    <Grid item md={4} sm={4} xs={12} xl={4} lg={4}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "1px solid #ccc",
                          borderRadius: "6px",
                          padding: 15,
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Cómo transferir el pago!
                        </Typography>
                        {order?.paymentType?.type === "Pagar en efectivo" && (
                          <p style={{ fontSize: 14 }}>
                            Envía el monto de la compra al número ####-#### y
                            luego adjunta una imagen con el comprobante de pago
                            en el campo abajo
                          </p>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              <div style={{ width: "95%", marginLeft: 10 }}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    padding: "10px",
                    letterSpacing: "1px",
                  }}
                >
                  productos
                </h2>

                <TableContainer
                  style={{ background: "#F6F9FC", padding: "15px" }}
                >
                  <Table
                    style={{
                      borderSpacing: "0 15px",
                      borderCollapse: "separate",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={Styles.tableHeading}>
                          {"Imagen"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Nombre del producto"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Descripción"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Cantidad"}
                        </TableCell>

                        <TableCell style={Styles.tableHeading}>
                          {"Tamaño"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Precio unitario"}
                        </TableCell>
                        <TableCell style={Styles.tableHeading}>
                          {"Precio total"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {order?.productDetails?.map((row) => {
                      return (
                        <TableBody>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            style={{
                              background: "#fff",
                              margin: "15px 0",
                              cursor: "pointer",
                            }}
                          >
                            <TableCell
                              style={{ borderRadius: "10px 0 0 10px" }}
                            >
                              <div>
                                <img
                                  style={{ width: 30, height: 30 }}
                                  src={row.images}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ color: "#444", width: "400px" }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              style={{ color: "#444", width: "300px" }}
                            >
                              {row.description}
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>{row.qty}</div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>{row.size}</div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>
                                ₡{row.basePrice}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div style={{ color: "#444" }}>₡{row.price}</div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default OrderDetail;
const styles = {
  card: {
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: 15,
  },
  titleTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#888",
    padding: "10px",
    margin: 0,
  },
  valueTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#222",
    margin: 0,
    padding: "10px",
  },
  hightLightTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#AD6D49",
    padding: "10px",
    margin: 0,
  },
  buyerName: {
    color: "#307682",
    fontSize: 12,
    fontWeight: "bold",
    padding: "10px",
    margin: 0,
  },
};

const Styles = {
  tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
};
