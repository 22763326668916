import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as Yup from "yup";
import Colors from "../../../components/Colors";

import DatePickerComponent from "../../../components/DatePickerComponent";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Tu nombre completo es requerido"),
  email: Yup.string().required("Correo Electrónico es requerido"),

  password: Yup.string()
    .required("Contraseña es requerido")
    .min(6, "Al menos 6 caracteres"),
});

export default function SignUp() {
  const navigation = useNavigate();
  const [errorMessage, setErrorMessgae] = React.useState(null);
  const [dateValue, setDateValue] = React.useState(null);

  const handleSubmit = async (values) => {
    values.dob = dateValue;
    console.log(values);
  };

  return (
    <Container style={{ width: "100%" }}>
      <Box marginTop={8} sx={{ boxShadow: 3 }} style={Styles.Box}>
        <Typography component="h3" variant="h6" style={Styles.SignUpText}>
          Iniciar sesión
        </Typography>
        <Formik
          initialValues={{
            name: "",
            phone: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <FormField>
                <Field
                  component={TextField}
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Correo Electrónico"
                  name="name"
                  disabled={false}
                />
                <DatePickerComponent
                  value={dateValue}
                  setValue={setDateValue}
                />
              </FormField>
              {/* {errors.name && touched.name ? <div>{errors.name}</div> : null} */}

              <Field
                component={TextField}
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Número de Teléfono"
                name="phone"
                disabled={false}
              />
              <Field
                component={TextField}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={false}
              />

              {/* {errorMessage && <p style={{ color: "red" }}>{errorMessage.msg}</p>} */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={Styles.SignUpButton}
              >
                Sign Up
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Terms and conditions
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Divider orientation="horizontal" flexItem style={Styles.Divider}>
          <Typography style={Styles.OrStyling}>OR</Typography>
        </Divider>
        <Typography component="h1" variant="h5">
          Already have an account?
        </Typography>
        <Button
          onClick={() => navigation(-1)}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          style={Styles.SignInButton}
        >
          SIGN IN
        </Button>
      </Box>
    </Container>
  );
}

const Styles = {
  SignInButton: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 7,
    width: "50%",
    textTransform: "none",
    fontSize: 12,
  },
  OrStyling: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 20,
    color: Colors.white,
    padding: 5,
    textAlign: "center",
  },
  Divider: {
    width: "50%",
    justifyContent: "center",
    alignSelf: "center",
  },
  Box: {
    padding: 30,
    borderRadius: 20,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  SignUpButton: {
    backgroundColor: Colors.darkBlue,
  },
  SignUpText: {
    fontSize: 15,
    width: "100%",
  },
};
const FormField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
