import React from "react";
import { Grid } from "@mui/material";

function VendorOrderStatus({
  text,
  balance,
  icon,
  mainContainer,
  childContainer,
  iconStyle,
  textStyle,
  balanceStyle,
}) {
  return (
    <Grid container xs={11} md={12} lg={12} style={mainContainer}>
      <Grid xs={11} md={10} item style={childContainer}>
        <div style={iconStyle}>{icon}</div>
        <div>
          <div style={textStyle}>{text}</div>
          <div style={balanceStyle}>{balance}</div>
        </div>
      </Grid>
    </Grid>
  );
}

export default VendorOrderStatus;
