import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "react-modal";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Colors from "./Colors";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { getCartTotal } from "../ContextApi/reducer";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Grid, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Counter from "./Counter";
import "./Navbar.css";
import { useStateValue } from "../ContextApi/StateProvider";
import { useNavigate } from "react-router-dom";

import { Input } from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";
export default function NavbarComponent({ storeData }) {
  const [{ cart, qty }, dispatch] = useStateValue();
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#343538";
  }

  function closeModal() {
    setIsOpen(false);
  }
  const navigation = useNavigate();

  const TotalValue = getCartTotal(cart);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const removeFromBasket = (id) => {
    //remove
    dispatch({
      type: "REMOVE_FROM_CART",
      id: id,
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const listMainMenu = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItemButton
          style={{ cursor: "pointer" }}
          onClick={() => navigation("/")}
        >
          Home
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton
          style={{ cursor: "pointer" }}
          onClick={() => navigation("UserAllOrders")}
        >
          All orders
        </ListItemButton>
      </List>
      <Divider />
    </Box>
  );
  const listCart = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {cart?.length <= 0 ? (
        <div style={{ height: "100vh" }}>
          <div style={Styles.emptyPersonalCartHeader}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(anchor, false)}
            />
            <div style={Styles.personalTextContainer}>
              <div style={Styles.storeName}>Personal Rainbow Grocery Cart</div>
              <div style={Styles.storeAddress}>Shopping in 94105</div>
            </div>
            <div />
          </div>
          <div style={Styles.emptyPersonalCartBody}>
            <div style={Styles.shopNowContainer}>
              <img
                style={{ width: 230, height: 150 }}
                src={require("../assets/emptycart.png")}
              />
              <div style={Styles.personalText1}>
                Your personal cart is empty
              </div>
              <div style={Styles.personalText2}>Shop Now</div>
            </div>
          </div>
          <div style={Styles.emptyCheckOut}>
            <div />
            <div>
              <div>10$ min to checkout</div>
            </div>
            <div style={Styles.emptyPrice}>
              <div>$0.00</div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div style={Styles.personalCartHeader}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(anchor, false)}
            />
            <div style={Styles.personalTextContainer}>
              <div style={Styles.storeName}>Personal Rainbow Grocery Cart</div>
              <div style={Styles.storeAddress}>Shopping in 94105</div>
            </div>
            <div />
          </div>
          <div style={Styles.personalCartBody}>
            <div style={Styles.storeInfoContainer}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div>
                  <img style={Styles.storeImg} src={storeData.img} />
                </div>
                <div style={{ marginLeft: 5 }}>
                  <div style={Styles.storeTitle}>{storeData.title}</div>
                  <div style={Styles.storeDeliveryDate}>{storeData.date}</div>
                  <div style={Styles.storeGuarantee}>
                    100% satisfaction guarantee
                  </div>
                </div>
              </div>
              <div>
                <div>${TotalValue.toFixed(2)}</div>{" "}
              </div>
            </div>
            <div style={Styles.firstDeliveryContainer}>
              <div style={Styles.firstDeliveryText}>
                Your first delivery is free!
              </div>
            </div>
            {cart.map((item) => {
              return (
                <div style={Styles.product}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div>
                      <img style={Styles.productImg} src={item.img} />
                    </div>
                    <div
                      style={{
                        marginLeft: 5,
                        width: 150,
                      }}
                    >
                      <div style={Styles.productTitle}>{item.title}</div>
                      <div style={Styles.productDeliverDate}>{item.date}</div>
                      <div style={Styles.storeGuarantee}>
                        100% satisfaction guarantee
                      </div>
                      <div
                        onClick={() => removeFromBasket(item.id)}
                        style={Styles.productRemove}
                      >
                        <DeleteIcon fontSize="small" />
                        Remove
                      </div>
                      <Counter
                        id={item.id}
                        price={item.price}
                        date={item.date}
                        img={item.img}
                        title={item.title}
                      />
                    </div>
                  </div>
                  <div>
                    <div style={{}}> {item.price}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={Styles.personalCartFooter}>
            <div />
            <div onClick={() => navigation("/UserCheckOut")} style={{}}>
              <div>10$ min to checkout</div>
            </div>
            <div style={Styles.cartTotalPrice}>
              <div>${TotalValue.toFixed(2)}</div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );

  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",

      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "60%",
    },
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <div style={Styles.mobileLoginText}>LogIn</div>
      </MenuItem>
      <MenuItem>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* icon place  */}
            <div onClick={toggleDrawer(anchor, true)}>
              <div
                style={{
                  backgroundColor: cart.length > 0 ? "lightgreen" : "lightgray",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 24,
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 10,
                  paddingRight: 10,
                  cursor: "pointer",
                }}
              >
                <ShoppingCartIcon style={{ color: "#343538" }} />
                <div style={{ color: "black" }}>{cart?.length}</div>
              </div>
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {listCart(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </MenuItem>
    </Menu>
  );
  return (
    <Grid
      container
      columns={{ xs: 12, sm: 12, md: 12 }}
      style={Styles.gridContainer}
    >
      <Grid style={Styles.menuContainer} item md={0.5} xs={1} xl={2}>
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* icon place  */}
            <div
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon />
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {listMainMenu(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </Grid>
      <Grid style={Styles.imgContainer} item md={1.5} xs={2.5} xl={2}>
        <img
          style={{ margin: -20, width: 200, height: 90 }}
          src={require("../assets/logo1.png")}
        />
      </Grid>
      <Grid item md={7.2} xs={4} xl={4}>
        <input
          type="text"
          id="inputID"
          style={Styles.inputText}
          placeholder="Search Product store and recipes"
        />
      </Grid>
      <Grid style={Styles.addressContainer} item md={1} xs={3} xl={2}>
        <div style={{ display: "flex" }} onClick={openModal}>
          <div>
            <LocationOnIcon style={Styles.colorWhite} />
          </div>
          <div style={{ color: "white" }}>94105</div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <Input
              disableUnderline
              style={{
                width: "50%",
                borderWidth: 2,
                borderColor: "black",
                borderRadius: 10,
                padding: 3,
              }}
              inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                <Autocomplete
                  apiKey="AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0"
                  {...props}
                  onPlaceSelected={(selected) => console.log(selected)}
                />
              )}
            />
          </div>
        </Modal>
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <header>
              <div>
                <span>Material UI</span>
                <Input
                  fullWidth
                  color="secondary"
                  inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                    <Autocomplete
                      apiKey="AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0"
                      {...props}
                      onPlaceSelected={(selected) => console.log(selected)}
                    />
                  )}
                />
              </div>
            </header>
          </div>
        </Modal> */}
      </Grid>
      <Grid style={{ display: "flex" }} md={0.5} xs={1} item>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <div style={Styles.webLoginText}>LogIn</div>

          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              {/* icon place  */}
              <div onClick={toggleDrawer(anchor, true)}>
                <div
                  style={{
                    backgroundColor:
                      cart.length > 0 ? "lightgreen" : "lightgray",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 24,
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 10,
                    paddingRight: 10,
                    cursor: "pointer",
                  }}
                >
                  <ShoppingCartIcon style={{ color: "#343538" }} />
                  <div style={{ color: "black" }}>{cart?.length}</div>
                </div>
              </div>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {listCart(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <div
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </div>
        </Box>
        {renderMobileMenu}
        {renderMenu}
      </Grid>
    </Grid>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "70vh",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: 2,
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const Styles = {
  mobileLoginText: {
    display: "flex",
    backgroundColor: Colors.seaGreen,
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
  },
  mobileCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  gridContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.seaGreen,
    top: 0,
    position: "fixed",
    zIndex: 100,
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
  inputText: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
  },
  addressContainer: {
    display: "flex",
    justifyContent: "center",
  },
  colorWhite: { color: Colors.white },
  webLoginText: {
    display: "flex",
    backgroundColor: Colors.lightGreen,
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    cursor: "pointer",
    color: "white",
  },
  webCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  emptyPersonalCartHeader: {
    height: "11vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  emptyPersonalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "78vh",
    display: "flex",
    justifyContent: "center",
  },
  personalTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  personalText1: { color: "#72767E", fontSize: 18, fontWeight: "600" },
  personalText2: { color: "#58B42F", fontSize: 18, fontWeight: "600" },
  emptyCheckOut: {
    backgroundColor: "white",
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F6F7F8",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
  },
  emptyPrice: {
    backgroundColor: "lightgray ",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },

  personalCartHeader: {
    height: "10vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    width: "100%",
    position: "sticky",
  },
  personalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "78vh",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
  },
  personalCartFooter: {
    backgroundColor: "white",
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: Colors.seaGreen,
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
    cursor: "pointer",
  },
  storeName: { fontSize: 15, fontWeight: "600", color: "black" },
  storeAddress: { fontSize: 12, fontWeight: "600", color: "gray" },
  shopNowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
  },
  cartTotalPrice: {
    backgroundColor: "lightgray ",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },
  storeInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    backgroundColor: "white",
    padding: 5,
  },
  storeImg: { width: 70, height: 70, borderRadius: 50 },
  storeTitle: { fontWeight: "600", color: "black" },
  storeDeliveryDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  storeGuarantee: {
    fontWeight: "700",
    color: "blue",
    fontSize: 14,
    cursor: "pointer",
    fontSize: 12,
    marginLeft: 2,
  },
  firstDeliveryContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "rgb(250, 241, 229)",
    marginTop: 10,
  },
  firstDeliveryText: {
    fontSize: "15",
    fontWeight: "600",
    color: " rgb(117, 0, 70)",
    padding: 8,
  },
  product: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    backgroundColor: "white",
    padding: 5,
  },
  productImg: { width: 70, height: 70, borderRadius: 50 },
  productTitle: {
    fontWeight: "600",
    color: "black",
  },
  productDeliverDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  productRemove: {
    fontWeight: "700",
    color: "gray",
    fontSize: 14,
    cursor: "pointer",
    fontSize: 12,
    marginLeft: 2,
  },
};
