import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useReducer } from "react";
import { useEffect } from "react";
import { auth } from "../firebase";
//Preparse the data layout

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => {
  const [vendor, setVendor] = React.useState({});

  onAuthStateChanged(auth, (currentVendor) => {
    setVendor(currentVendor);
  });
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentVendor) => {
      setVendor(currentVendor);
    });
    return unsubscribe;
  }, []);

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
